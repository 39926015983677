import React, { useState, useEffect } from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import { elastic as Menu } from 'react-burger-menu'
import logoWhite from "../images/enimeris-logo-website-white.png";
import fbWhite from "../images/enimeris-social-icons-white-fb.svg";
import linkedInWhite from "../images/enimeris-social-icons-white-linkedin.svg";
import htmlDecode from "html-entities-decoder"

import "./FooterMenu.css";


function FooterMenu(props) {

    console.log(props)
    const menu = props.footerMenu ? props.footerMenu.menuItems.edges : [];

    useEffect(() => {
        if (document.querySelectorAll(".slash")[0]) {
            document.querySelectorAll(".slash")[0].style.display = "none";
        }
    }, [])

    return (
        <div className="footer-container mx-auto px-5">
            <nav className=" container m-auto flex flex-wrap lg:flex-no-wrap flex-col lg:flex-row lg:justify-around items-start pt-16 pb-8">
                <div className="flex flex-row flex-wrap justify-between w-full md:w-auto pb-8 lg:pb-0">
                    <img className="w-64 m-auto" src={logoWhite} alt="white-logo" />
                    <div className="w-full flex justify-center mt-8">
                        <a href="https://www.facebook.com/enimeris/" target="_blank" rel="noopener"><img src={fbWhite} alt="facebook-icon" style={{width: "45px"}} /></a>
                        <a href="https://www.linkedin.com/company/enimeris/" target="_blank" rel="noopener" className="pl-4"><img src={linkedInWhite} alt="linkedin-icon" style={{width: "45px"}} /></a>
                    </div>
                </div>
                <div className="items-center">
                    <ul className="flex flex-wrap lg:flex-no-wrap flex-col lg:flex-row footer-links">
                        {menu.map((tab, index) => {
                            if (!tab.node.label.includes("#")) {
                                return (
                                    <li key={`hMenu-${index}`} className="md:pr-2 xl:pr-8 pt-8 md:pt-0">
                                        <div>
                                            {tab.node.label}
                                        </div>
                                        <ul className="text-sm mt-4">
                                            {tab.node.childItems.edges && tab.node.childItems.edges.map((subItem, yndex) => (
                                                <li key={`f-sub-item-${yndex}`} className="mt-3 w-full f-link-w">
                                                    {subItem.node.url.includes("https://f2.enimeris.com")
                                                        ?
                                                        <Link to={subItem.node.url.replace("https://f2.enimeris.com", "")}>
                                                            {htmlDecode(subItem.node.label)}
                                                        </Link>
                                                        :
                                                        <React.Fragment>

                                                            { subItem.node.url.includes("https://")
                                                                ?
                                                                <a href={subItem.node.url + "/"}>
                                                                    {htmlDecode(subItem.node.label)}
                                                                </a>
                                                                :
                                                                <Link to={subItem.node.url.includes("#") ? subItem.node.url.replace("#", "/") : "/" + subItem.node.url.replace("http://", "") + "/"}>
                                                                    {htmlDecode(subItem.node.label)}
                                                                </Link>
                                                            }
                                                        </React.Fragment>

                                                    }
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                )
                            }
                        })}
                    </ul>
                </div>
            </nav>
            <div className="w-full text-white pt-16 pb-8">
                <ul className="flex flex-row flex-wrap justify-center">
                    {menu.map((tab, index) => {
                        if (tab.node.label.includes("#")) {
                            return (
                                <li key={`subMenu-${index}`} class="sub-link-mob pb-2" >
                                    <span className="px-2 slash">|</span>
                                    <Link to={tab.node.url.replace("https://f2.enimeris.com", "")}>
                                        {htmlDecode(tab.node.label.replace("#", ""))}
                                    </Link>
                                </li>
                            )
                        }
                    })}

                </ul>
                <p className="text-center">© 2021 Enimeris. All rights reserved.</p>
                <h5 className='pl-2 text-center pt-2'><a href='https://w3vitals.com' target='_blank'>Δημιουργία ιστοσελίδας από W3Vitals</a>.</h5>
            </div>
        </div>
    )
}

export default FooterMenu;