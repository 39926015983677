import React, { useState } from 'react'
import { Link } from 'gatsby'
import { elastic as Menu } from 'react-burger-menu'
import logoBlue from "../images/enimeris-logo-website-blue.png";
import logoWhite from "../images/enimeris-logo-website-white.png";
import htmlDecode from "html-entities-decoder"
import sortDown from "../images/sort-down-blue.svg"
import "./MainMenu.css";

const burgerStyles = {
    bmBurgerButton: {
        display: "block",
        position: "absolute",
        width: "26px",
        height: "22px",
        left: "25px",
        top: "20px"
    },
    bmBurgerBars: {
        background: '#143b68'
    },
    bmBurgerBarsHover: {
        background: '#a90000'
    },
    bmCrossButton: {
        height: '24px',
        width: '24px'
    },
    bmCross: {
        background: '#bdc3c7'
    },
    bmMenuWrap: {
        position: "fixed",
        height: "100%",
        top: "0!important",
        left: "0"
    },
    bmMenu: {
        background: "rgb(20, 59, 104)",
        padding: "0.5em 1.5em 0",
        fontSize: "1.15em",
        minWidth: "300px"
    },
    bmMorphShape: {
        fill: 'rgb(20, 59, 104)'
    },
    bmItemList: {
        color: "#b8b7ad",
        padding: "0.8em"
    },
    bmItem: {
        display: "block!important",
        marginTop: "1rem",
        outline: "none"
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
        top: "0!important"
    }
}

function MainMenu(props) {

    const [menuState, setMenuState] = useState(false);
    console.log(props)
    const menu = props.headerMenu ? props.headerMenu.menuItems.edges : [];

    let isMenuOpen = function (state) {
        setMenuState(state.isOpen)
    };

    let nestCounter = 0;

    function createSubMenu(subs, parentLabel) {
        // console.log(subs)
        if (parentLabel !== "Υπηρεσίες" && parentLabel !== "Services") {
            return (
                <ul>
                    {subs.map((subLink, index) => (
                        <li key={`sublink-${index}`} >
                            {subLink.node.url.includes("https://")
                                ?
                                <a href={`${subLink.node.url}/`} className="menu-g-link">
                                    {subLink.node.label}
                                </a>
                                :
                                <Link to={`/${subLink.node.url.replace("http://", "")}/`} className="menu-g-link">
                                    {subLink.node.label}
                                </Link>
                            }
                        </li>
                    ))}
                </ul>
            )
        } else {
            return (
                <div>
                    {props.services && props.services.map((serviceC, k) => (
                        <div className="px-4" key={`category-name-${k}`}>
                            <div className="text-left text-2xl font-bold tracking-wider pl-4 pb-2">
                                <span>{serviceC.name}</span>
                            </div>
                            {serviceC.services.edges.map((service, d) => (
                                <div className={d < serviceC.services.edges.length - 1 ? "mn-tab-link" : "text-sm"} key={`category-link-${d}`}>
                                    {console.log(d, serviceC.services.edges.length - 1)}
                                    <Link to={service.node.uri} className="menu-g-link">
                                        {htmlDecode(service.node.title)}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )
        }
    }

    function createSubMobile(subs, parentLabel) {
        if (parentLabel !== "Υπηρεσίες" && parentLabel !== "Services") {
            return (
                <ul className="pl-4 pt-2">
                    {subs.map((subLink, index) => (
                        <li key={`sub-mob-link-${index}`} className="flex" >

                            {subLink.node.url.includes("https://")
                                ?
                                <a href={`${subLink.node.url}/`} className="py-2">
                                    {subLink.node.label}
                                </a>
                                :
                                <Link to={`/${subLink.node.url.replace("http://", "")}/`} className="py-2" >
                                    {subLink.node.label}
                                </Link>
                            }
                        </li>
                    ))}
                </ul>
            )
        }
    }

    function subMenuClick(index) {
        let isOpen = document.querySelectorAll(".m-dropdown-content")[index].style.display;

        if (isOpen === "none" || isOpen === "") {
            document.querySelectorAll(".m-dropdown-content")[index].style.display = "block";
            document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(90deg)";
        } else {
            document.querySelectorAll(".m-dropdown-content")[index].style.display = "none";
            document.querySelectorAll(".h-chev-down")[index].style.transform = "rotate(0deg)";
        }
    }


    return (
        <div className="nav-container mx-auto px-5 flex md:block justify-center">
            <nav className="flex flex-row md:justify-between">
                <div className="flex flex-row justify-between w-full md:w-auto">
                    {/* <p id="hamburgerbtn" className="md:hidden bg-purple-800">
                        menu
                    </p> */}
                    <div id="mainMenu" className="block md:hidden"></div>
                    <Menu styles={burgerStyles} isOpen={menuState} onStateChange={isMenuOpen} className="block md:hidden" >
                        <div className="pb-4">
                            <Link to={props.locale === "el" ? '/' : `${props.locale}/`}>
                                <img className="w-32 m-auto" src={logoWhite} alt="white-logo" />
                            </Link>
                        </div>

                        {menu.map((tab, index) => {
                            return (
                                <div key={`mMenu-${index}`} className="menu-item text-left text-white" style={{ "marginTop": "1rem" }}>

                                    {tab.node.url === "#"
                                        ?
                                        <React.Fragment>
                                            <div onClick={() => subMenuClick(index)} className="flex justify-between items-center" >
                                                <span className="h-dropdown">
                                                    {tab.node.label}
                                                </span>
                                                <img className="mob-sort-down h-chev-down" src={sortDown} alt="sort-down" />
                                            </div>
                                            <div className="m-dropdown-content hidden">
                                                {tab.node.childItems.edges.length > 0 && (
                                                    createSubMobile(tab.node.childItems.edges, tab.node.label)
                                                )}
                                            </div>
                                        </React.Fragment>
                                        :

                                        <React.Fragment>
                                            {
                                                tab.node.url.includes("https")
                                                    ?
                                                    <a href={`${tab.node.url}/`}>
                                                        {tab.node.label}
                                                    </a>
                                                    :
                                                    <Link to={`/${tab.node.url.replace("http://", "")}/`}>
                                                        {tab.node.label}
                                                    </Link>
                                                
                                            }

                                            <div className="h-chev-down hidden"></div>
                                            <div className="m-dropdown-content hidden"></div>
                                        </React.Fragment>
                                    }
                                    {/* {tab.node.childItems.edges.length > 0 && (
                                        createSubMenu(tab.node.childItems.edges, tab.node.label)
                                    )} */}
                                </div>
                            )
                        })}


                        <div className="menu-item text-left text-white" style={{ "marginTop": "1rem" }}>
                            <React.Fragment>
                                <div onClick={() => subMenuClick(document.querySelectorAll(".m-dropdown-content").length - 1)} className="flex justify-between items-center" >
                                    <span className="h-dropdown">
                                        {props.currentPage.language}
                                    </span>
                                    <img className="mob-sort-down h-chev-down" src={sortDown} alt="sort-down" />
                                </div>
                                <div className="m-dropdown-content hidden">
                                    <ul className="pl-4 pt-4">
                                        {props.availableVersions.map((item, index) => (
                                            <li key={`sub-mob-l-link-${index}`} className="flex" >
                                                <Link key={`alt-lang-${index}`} to={item.uri === "arxiki/" ? "/" : `${item.uri}`} className="text-white">
                                                    {item.language.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>

                                </div>
                            </React.Fragment>
                        </div>
                    </Menu>
                    <Link to={props.locale === "el" ? '/' : `${props.locale}/`} className="flex items-center">
                        <img className="w-56 md:w-64 m-auto" src={logoBlue} alt="white-logo" />
                    </Link>
                </div>
                <div className="hidden md:flex items-center text-xl">
                    <ul className="hidden md:flex md:flex-row nav">
                        {menu.map((tab, index) => {
                            return (
                                <li key={`hMenu-${index}`} className="pr-5">
                                    {tab.node.url === "#"
                                        ?
                                        <span>
                                            {tab.node.label}
                                        </span>
                                        :
                                        (tab.node.url.includes("https")
                                            ?
                                            <a href={`${tab.node.url}/`}>
                                                {tab.node.label}
                                            </a>
                                            :
                                            <Link to={`/${tab.node.url.replace("http://", "")}/`}>
                                                {tab.node.label}
                                            </Link>
                                        )

                                    }
                                    {tab.node.childItems.edges.length > 0 && (
                                        createSubMenu(tab.node.childItems.edges, tab.node.label)
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                    {/* {(props.availableVersions && props.availableVersions.length > 0) && (
                        <div className="language-switcher flex items-center">
                            <Link to={props.currentPage.currentPage} style={{color: "#143b68"}}>
                                {props.currentPage.language}
                            </Link>
                            <img className="lang-sort-down" src={sortDown} alt="sort-down" />
                            <div>
                                {props.availableVersions.map((item, index) => (
                                    <Link key={`alt-lang-${index}`} to={item.uri === "arxiki/" ? "/" : `${item.uri}`} className="text-white menu-g-link">
                                        {item.language.name}
                                    </Link>
                                ))}
                            </div>
                        </div>
                    )} */}
                </div>
            </nav>
        </div>
    )
}

export default MainMenu;